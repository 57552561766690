import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Privacy from "./Privacy";
import DataDeletion from "./DataDeletion";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material/styles";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: "#345a71",
      main: "#02314e",
      dark: "#012236",
    },
    secondary: {
      light: "#f43733",
      main: "#f20500",
      dark: "#a90300",
    },
    background: {
      default: "#02314e",
    },
  },
};
const theme = createTheme(themeOptions);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
      </ThemeProvider>
      <Routes>
        <Route path="/" Component={App} />
        <Route path="/privacy" Component={Privacy} />
        <Route path="/data-deletion" Component={DataDeletion} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
