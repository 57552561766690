import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Divider,
} from "@mui/material";
import logo from "../assets/dartboard.svg";

function Privacy() {
  return (
    <Box sx={{ p: 10 }}>
      <Card sx={{ maxWidth: 345, mx: "auto" }} elevation={10}>
        <CardHeader
          title="Sad to see you go!"
          subheader="Are you realy sure you want to stop playing with your friends?"
        />
        <Divider />
        <CardMedia
          component="img"
          sx={{ p: 5 }}
          height=""
          image={logo}
          alt="Logo"
        />
        <CardContent>
          If you wish to request the deletion of your account you can do so by
          sending an email to support@throwl.com. We will reach out to you
          within 5 business days and make sure your account will be removed
        </CardContent>
      </Card>
    </Box>
  );
}

export default Privacy;
