import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Link,
  Typography
} from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';

import logo from '../assets/dartboard.svg';

function App() {
  return (
    <Box sx={{ p: 10 }}>
      <Card sx={{ maxWidth: 345, mx: "auto" }} elevation={10}>
        <CardHeader
          title="Throwl"
          subheader="Keep track of your dart games"
        />
        <Divider />
        <CardMedia
          component="img"
          sx={{ p: 5 }}
          height=""
          image={logo}
          alt="Logo"
        />
        <Divider />
        <CardContent>
          <Typography variant="subtitle2" color="text.secondary">
            Play against friends and show who's the better darts player!
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{ lineHeight: 'inherit', letterSpacing: 'inherit', }}>
            Keep track of your dart game and gain useful insights in how your games went with statistics like most
            thrown and your average progression throughout the game.
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button 
            size="small" 
            component="a" 
            target="_BLANK"
            rel="noreferrer"
            href="https://apps.apple.com/us/app/throwl/id1588360439?itsct=apps_box_badge&amp;itscg=30200" 
            variant="outlined" 
            startIcon={<AppleIcon />} >
            Download
          </Button>

          <Button 
            size="small" 
            component="a"
            target="_BLANK"
            rel="noreferrer"
            href="https://play.google.com/store/search?q=throwl&c=apps&gl=NL&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            variant="outlined"
            startIcon={<GoogleIcon />}>
            Download
          </Button>
        </CardActions>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button component="a" href="mailto:support@throwl.com">Get support</Button>
          <Button component="a" href="mailto:suggestions@throwl.com">Make a suggestion</Button>
        </CardActions>
      </Card>
      <Box sx={{ textAlign: 'center', p: 1 }}>
        <Typography variant="caption">
          <Link href="privacy/" color="primary.contrastText" target="_blank" rel="noopener noreferrer">
            Privacy Statement
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
